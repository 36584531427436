<template>
    <div>
        <Index />
    </div>
</template>

<script>
import Index from './SEduClassroomPatrolContent/index.vue';
export default {
    components: {
        Index
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>