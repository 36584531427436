<template>
    <div class="dict-left-content">
        <div class="create-exam-wrap">
            <div class="title">巡课内容分组</div>
            <div class="create-an-exam" @click="create">
                <xk-button
                    type="primary"
                    v-hasPermi="['patrolContent:groupAdd']"
                    @click="create"
                >
                    <span>添加</span>
              </xk-button>
            </div>
        </div>
        <div class="exam-list">
            <el-select style="width:100%" v-model="contentType.type" clearable placeholder="选择标签">
                <el-option v-for="item in labelData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="exam-list-wrapper">
                <div>
                    <div v-for="item in tableftdata" :key="item.id">
                        <div class="listData" @click="cutList(item)"
                            :class="{ 'active': value === item.id }">
                            <div class="listLeft">
                                <div class="title">{{ item.groupName }}</div>
                            </div>
                            <div>
                                <div class="tag" v-if="item.groupType == '1'">师</div>
                                <div class="tag" v-if="item.groupType == '2'">生</div>
                            </div>
                            <div class="operationImg">
                                <img :src="editUrl" v-hasPermi="['patrolContent:groupEdit']" @click="edit(item)" alt="" />
                                <img :src="delUrl" v-hasPermi="['patrolContent:groupDelete']" @click="del(item)" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {
    mapState
} from "vuex";

export default {
    props: {
        contentType: {
            type: Object
        },
        value: {
            type: String,
        },
        tableftdata: {
            type: Array
        }
    },
    data() {
        return {
            type: "",
            labelData: [{
                value: '',
                label: '全部'
            }, {
                value: '1',
                label: '教师'
            }, {
                value: '2',
                label: '学生'
            }],
            filterText: "",
            list: [],
            value1: [],
            value2: [],
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
            permissions: state => state.permissions,
        }),
        plusUrl() {
            return require('@/assets/images/push1.png')
        },
        delUrl() {
            return require('@/assets/images/del-l.png')
        },
        editUrl() {
            return require('@/assets/images/edit-l.png')
        },
        expandUrl() {
            return require('@/assets/images/expand-btn.png')
        },
        emptyUrl() {
            return require('@/assets/images/no-exam.png')
        },
        sortImg() {
            return require('@/assets/images/sort.png')
        },
        sortListAuth() {
            return this.permissions.find(item => {
                return item == 'oaGroup:sort'
            })
        }
    },
    methods: {
        create() {
            this.$emit("create");
        },
        edit(data) {
            this.$emit("edit", data);
        },
        del(data) {
            this.$emit("del", data);
        },
        cutList(data) {
            this.$emit("cutList", data);
        },
    },
};

</script>
  
<style lang="scss" scoped>
.active {
    background-color: #EDF4FA;
    border: 1px solid #3C7FFF !important;
    font-weight: 500 !important;
}

.emtpy {
    position: relative;
    text-align: center;
    margin-top: 50%;
}

.no-data {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    font-weight: bold;
    color: #878787;
}

.icon-down {
    color: #ffffff;
    font-size: 12px;
    margin: 0 3px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
}

.dict-left-content {
    width: 280px;
    margin-right: 10px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 6px;
    float: left;

    .exam-list {
        padding: 0 16px;

        .exam-list-wrapper {
            height: calc(100vh - 230px);
            overflow-y: auto;
            margin-top: 10px;
        }
    }

    .listData {
        height: 52px;
        border-radius: 4px;
        align-items: center;
        padding: 9px 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eff2f3;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .listLeft {
            display: flex;
            margin: auto;
            .title {
                width: 110px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #363B40;
                line-height: 16px;
                // overflow: hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
            }         
        }
        .tag {
                width: 20px;
                height: 16px;
                line-height: 16px;
                margin: 0 10px;
                text-align: center;
                border: 1px solid #E1E3E6;
                border-radius: 4px;
                font-size: 10px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #C8CACC;
            }

        .operationImg {
            width: 60px;
            height: 20px;
            img {
                width: 20px;
                height: 20px;
                margin: 0 5px;
            }

        }

        &:hover {
            background-color: #edf4fa;
        }
    }


    .create-exam-wrap {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        align-items: center;

        .title {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
        }
    }

    .create-an-exam {
        cursor: pointer;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
        text-align: center;

        img {
            width: 12px;
            height: 12px;
            margin-right: 8px;
        }
    }

    .filter-input {
        margin-top: 5px;
    }
}

.dict-left-content /deep/ .filter-input .el-input__inner {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;
}
</style>
  