<template>
  <div class="center-main">
    <div v-if="!errorShow">
      <loading :loadingShow="loadingShow"></loading>
      <div v-if="!loadingShow">
        <tab-left
          :contentType="contentType"
          :value="groupId"
          :tableftdata="tableftdata"
          @edit="editFormGroup"
          @del="delFormGroup"
          @cutList="cutList"
          @create="createFormGroup"
        />
        <div class="left-content">
          <div class="filter-form">
            <div class="title">巡课内容</div>
            <div class="butn">
              <xk-button
                type="primary"
                v-hasPermi="['patrolContent:contentAdd']"
                @click="addSchedule"
              >
                <span>添加</span>
              </xk-button>
            </div>
          </div>
          <table-data
            ref="tableRef"
            v-loading="loadingTable"
            id="table"
            :config="table_config"
            :tableData="table_data"
            :backgroundHeader="backgroundHeader"
            style="margin-top: -10px"
          >
            <template v-slot:operation="slotData">
              <el-button
                type="text"
                class="primary"
                v-hasPermi="['patrolContent:contentEdit']"
                @click="edit(slotData.data)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="danger"
                v-hasPermi="['patrolContent:contentDelete']"
                @click="del(slotData.data)"
                >删除</el-button
              >
            </template>
          </table-data>
          <Pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageRow"
            @pagination="getList"
          />
          <dialog-wrapper
            :dialogObj="dialogObj"
            @handleClose="handleFormGroupClose"
          >
            <el-form
              :model="formGroupRuleForm"
              :rules="formGroupRules"
              ref="ruleForm"
              label-width="120px"
            >
              <el-form-item label="分组名称" prop="groupName">
                <el-input
                  v-model="formGroupRuleForm.groupName"
                  placeholder="请输入"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="分组类型" prop="groupType">
                <el-select v-model="formGroupRuleForm.groupType" :disabled="formGroupRuleForm.id != ''" style="width: 100%">
                  <el-option value="1" label="教师"></el-option>
                  <el-option value="2" label="学生"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="设置分值" prop="setScore">
                <el-switch v-model="formGroupRuleForm.setScore" active-value="1" inactive-value="0"></el-switch>
              </el-form-item>
              <div :style="{ 'text-align': 'center' }">
                <xk-button type="primary" @click="handleFormGroupSave"
                  >确定</xk-button
                >
                <xk-button type="info" @click="handleFormGroupClose"
                  >取消</xk-button
                >
              </div>
            </el-form>
          </dialog-wrapper>
          <dialog-wrapper
            :dialogObj="dialogObj1"
            @handleClose="handleFormGroupClose1"
          >
            <el-form
              v-if="dialogObj1.dialogVisible"
              :model="behaviorData"
              :rules="behaviorDataRules"
              ref="ruleForm1"
              label-width="79px"
            >
              <div class="content">
                <el-form-item label="巡课内容" prop="content">
                  <el-input
                    type="textarea"
                    v-model="behaviorData.content"
                    placeholder="请输入"
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="评价方式" prop="type">
                  <el-radio-group v-model="behaviorData.type" @input="typeChange">
                    <el-radio label="1">单选</el-radio>
                    <el-radio label="2">多选</el-radio>
                    <el-radio label="3">客观评价</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="behaviorData.type != '3'" label="设置选项" prop="list">
                  <draggable v-model="behaviorData.list">
                    <div class="radioSet" v-for="(item, index) in behaviorData.list" :key="index">
                      <img :src="sortUrl" alt="" class="drag-sort">
                      <el-input
                        v-model="item.name"
                        placeholder="请输入"
                        maxlength="30"
                        show-word-limit
                        class="show-cover-word"
                      ></el-input>
                      <div v-if="setScore == '1'" class="score">
                        <el-input-number
                          class="num"
                          :step-strictly="true"
                          v-model="item.score"
                          controls-position="right">
                        </el-input-number>
                        <span class="text">分</span>
                      </div>
                      <span class="del" :class="setScore == '0' ? 'del2' : ''" @click="delName(index)">删除</span>
                    </div>
                   </draggable>
                  <div class="add" @click="addName">
                    <img :src="plusUrl" alt="plus" />
                    <span>添加选项</span>
                  </div>
                </el-form-item>
                <el-form-item v-if="behaviorData.type == '3' && this.setScore == '1'" label="评价分值">
                  <div class="setScore">
                    <el-input-number
                      v-model="behaviorData.list2[0].lowScore"
                      :step-strictly="true"
                      controls-position="right">
                    </el-input-number>
                    <span class="line"></span>
                    <el-input-number
                      v-model="behaviorData.list2[0].highScore"
                      :step-strictly="true"
                      controls-position="right">
                    </el-input-number>
                  </div>
                  <div class="tip">
                    <img :src="tipUrl" alt="">
                    <span>评价时将在该范围内填写分值</span>
                    </div>
                </el-form-item>
              </div>
              <div :style="{ 'text-align': 'right' }">
                <xk-button type="info" @click="handleFormGroupClose1"
                  >取消</xk-button
                >
                <xk-button type="primary" @click="changeGrouping"
                  >确定</xk-button
                >
              </div>
            </el-form>
          </dialog-wrapper>
        </div>
      </div>
    </div>
    <error v-else :errorShow="errorShow"></error>
  </div>
</template>

<script>
import {
  // 业务组件
  FilterData,
//   TableData,
  Pagination,
  // 功能组件
  Loading,
  Error,
  DialogWrapper,
  // 工具函数
  throttle,
  debounce,
} from "common-local";
import draggable from "vuedraggable";
import TableData from "@/components/scrollWrapper/Sub/TableData"
import tabLeft from "./tabLeft";
import { mapState } from "vuex";
import { classScheduleModel } from "@/models/ClassSchedule.js";
export default {
  name: "SEduClassroomPatrolContentIndex",
  components: {
    tabLeft,
    FilterData,
    TableData,
    Pagination,
    DialogWrapper,
    Loading,
    Error,
    draggable
  },
  props: {
    accessStatus: String,
  },
  data() {
    return {
      contentType: {
        type: ''
      },
      //左侧添加按钮数据
      formGroupRuleForm: {
        groupName: "",
        groupType: "",
        setScore: "0",
        id: "",
      },
      //右侧添加按钮数据
      behaviorData: {
        id: "",
        content: "",
        type: "1",
        groupId: "",
        groupType: "",
        list:[
          {
            name: '',
            score: undefined,
            sort: '',
            lowScore: undefined,
            highScore: undefined
          },
          {
            name: '',
            score: undefined,
            sort: '',
            lowScore: undefined,
            highScore: undefined
          }
        ],
        list2: [
          {
            name: '',
            score: undefined,
            sort: '',
            lowScore: undefined,
            highScore: undefined
          }
        ]
      },
      formGroupRules: {
        groupName: [
          { required: true, message: "请输入分组名称", trigger: "blur" },
        ],
        groupType: [{ required: true, message: "请选择分组类型", trigger: "change" }],
        setScore: [
          { required: true, message: "请选择设置分值", trigger: "change" },
        ],
      },
      behaviorDataRules: {
        content: [
          { required: true, message: "请输入巡课内容", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择评价方式", trigger: "change" }],
        list: [{ required: true, message: "请输入选项内容", trigger: "blue" }],
        // lowScore: [{ required: true, message: "请输入评价分值", trigger: "blue" }],
      },

      // 表格配置
      table_config: {
        thead: [
          {
            label: "巡课内容",
            prop: "content",
            type: "popoverText",
            align: 'center',
            labelWidth: '300px'
          },
          {
            label: "内容类型",
            align: 'center',
            type: "function",
            callBack(row) {
              if (row.type == 1) {
                return "单选";
              } else if (row.type == 2) {
                return "多选";
              } else {
                return "客观评价";
              }
            },
          },
          {
            label: "选项内容",
            prop: "name",
            align: "center",
            labelWidth: '400px',
          },
          {
            label: "操作",
            type: "slot",
            align: 'center',
            fixed: "right",
            slotName: "operation",
            className: 'text-spacing-reduction'
          },
        ],
        rowkey: "id",
        check: false,
        height: "",
      },
      // 表格数据
      table_data: [],
      // 分页器
      total: 0,
      listQuery: {
        pageNum: 1,
        pageRow: 20,
        groupId: "",
      },

      // 功能组件
      errorShow: false,
      loadingShow: false,
      loadingTable: false,

      // 表头
      backgroundHeader: "#FAFBFC",
      // 表头字体
      headerColor: "",
      template_info: [],
      // 左侧【添加/编辑】
      dialogObj: {
        title: "",
        dialogVisible: false,
        width: "500px",
      },
      //列表页【添加/编辑】
      dialogObj1: {
        title: "",
        dialogVisible: false,
        width: "640px",
      },
      groupingStatus: "add",
      //左侧行为标签列表数据
      tableftdata: [],
      groupId: "",
      setScore: "",
      editSetScore: "",
      groupType: "",
    };
  },
  watch: {
    'contentType.type'() {
      this.tableftInit()
    }
  },
  computed: {
    plusUrl() {
      return require("@/assets/images/push1.png");
    },
    tipUrl() {
      return require("@/assets/images/tip.png");
    },
    sortUrl() {
      return require("@/assets/images/drag-sort.png");
    },
  },
  created() {
    this.tableftInit();
  },
  mounted() {
    setTimeout(() => {
      this.table_config.height =
        document.body.clientHeight -
        document.getElementById("table").offsetTop -
        140;
      window.onresize = debounce(() => {
        if (!document.getElementById("table")) return;
        const height =
          document.body.clientHeight -
          document.getElementById("table").offsetTop -
          140;
        this.table_config.height = height.toString();
      }, 200);
    }, 100);
  },
  methods: {
    /**
     * @Description: 内容类型切换
     * @Author: pyl
     * @Date: 2024-08-23 11:11:59
     */    
    typeChange(value) {
      if (value == '2') {
        const arr = []
        if (this.behaviorData.list.length > 0) {
          this.behaviorData.list.forEach(item => {
            if (item.name != '' || item.score != undefined) {
              arr.push(item)
            }
          })
        }
        this.behaviorData.list = arr
        const num = this.behaviorData.list.length >= 4 ? 0 : 4 - this.behaviorData.list.length
        num && Array.from(Array(num).keys()).forEach(() => {
          this.behaviorData.list.push({
            name: '',
            score: undefined,
            sort: '',
            lowScore: undefined,
            highScore: undefined
          })
        })
      } else if (value == '1') {
        const arr = []
        if (this.behaviorData.list.length > 0) {
          this.behaviorData.list.forEach(item => {
            if (item.name != '' || item.score != undefined) {
              arr.push(item)
            }
          })
        }
        this.behaviorData.list = arr
        const num = this.behaviorData.list.length >= 2 ? 0 : 2 - this.behaviorData.list.length
        num && Array.from(Array(num).keys()).forEach(() => {
          this.behaviorData.list.push({
            name: '',
            score: undefined,
            sort: '',
            lowScore: undefined,
            highScore: undefined
          })
        })
      }
    },
    /**
     * @Description: 添加单选设置
     * @Author: pyl
     * @Date: 2024-08-23 10:40:07
     */    
    addName() {
      this.behaviorData.list.push({
        name: '',
        score: undefined,
        sort: '',
        lowScore: undefined,
        highScore: undefined
      })
    },
    /**
     * @Description: 删除单选设置
     * @Author: pyl
     * @Date: 2024-08-23 10:40:07
     */    
    delName(index) {
      if (this.behaviorData.type == '1' && this.behaviorData.list.length == 1) {
        return this.$message.warning('评价方式为单选时，设置选项至少保留一个')
      } else if (this.behaviorData.type == '2' && this.behaviorData.list.length == 2) {
        return this.$message.warning('评价方式为多选时，设置选项至少保留两个')
      }
      this.behaviorData.list.splice(index, 1)
    },
    /**
     * @Description: 获取巡课内容列表
     * @Author: pyl
     * @Date: 2024-08-30 15:43:29
     */    
    getList() {
      if (this.tableftdata.length > 0) {
        if (this.groupId) {
          this.listQuery.groupId = this.groupId;
        } else {
          this.listQuery.groupId = this.tableftdata[0].id;
        }
        const classSchedule = new classScheduleModel();
        this.loadingTable = true;
        classSchedule.getBehaviorDataList(this.listQuery).then((res) => {
          this.handleRes(res, () => {
            this.table_data = res.data.data.list;
            res.data.data.list.forEach((item) => {
              if (item.type == '3') {
                this.$set(item, 'name', '-')
              } else {
                let name = ''
                item.list.forEach((i, j) => {
                  if (j < item.list.length - 1) {
                    name = name + i.name + '；'
                  } else {
                    name = name + i.name + ''
                  }
                })
                this.$set(item, 'name', name)
              }
            })
            this.total = res.data.data.totalCount;
          });
          this.loadingTable = false;
        }).catch(err => {
          this.loadingTable = false;
        })
      }else{
        this.table_data = []
        this.total = 0
        this.loadingTable = false
      }
    },
    /**
     * @Description: 切换查看巡课内容
     * @Author: pyl
     * @Date: 2024-08-30 16:13:13
     * @param {*} data
     */    
    cutList(data) {
      this.groupId = data.id;
      this.setScore = data.setScore;
      this.groupType = data.groupType;
      this.listQuery.groupId = this.groupId;
      this.getList()
    },

    /**
     * @Description: 左侧巡课内容分组列表数据
     * @DoWhat: 巡课内容分组列表
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-14 16:59:08
     */
    async tableftInit() {
      this.loadingTable = true;
      const classSchedule = new classScheduleModel();
      classSchedule
        .getBehaviorLabelList({ groupType: this.contentType.type })
        .then((res) => {
          this.handleRes(res, () => {
            this.loadingTable = false;
            this.tableftdata = res.data.data;
            if (this.tableftdata.length > 0) {
              this.groupId = this.tableftdata[0].id;
              this.setScore = this.tableftdata[0].setScore;
              this.groupType = this.tableftdata[0].groupType;
              this.getList();
            } else {
              this.table_data = []
              this.total = 0
              this.groupId = ''
            }
          });
        });
    },

    /**
     * @Description: 左侧栏编辑
     * @DoWhat: 编辑
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:42:15
     */
    editFormGroup(data) {
      this.formGroupRuleForm.groupName = data.groupName;
      this.formGroupRuleForm.id = data.id;
      this.formGroupRuleForm.groupType = data.groupType;
      this.editSetScore = data.setScore
      this.formGroupRuleForm.setScore = data.setScore;
      this.dialogObj.title = "编辑分组";
      this.groupingStatus = "edit";
      this.dialogObj.dialogVisible = true;
    },
    /**
     * @Description: 关闭、取消添加/编辑弹框
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:43:57
     */
    handleFormGroupClose() {
      this.$refs.ruleForm.resetFields(); // 清空校验
      this.dialogObj.dialogVisible = false;
      console.log(this.formGroupRuleForm, 'this.behaviorData')
    },
    /**
     * @Description: 左侧栏删除
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:59:46
     */
    delFormGroup(data) {
      this.$confirm("提示", {
        message: '删除分组后，分组下的巡课内容将全部删除，且不可恢复，是否继续？',
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            id: data.id,
          };
          this._fet("/school/schoolPatrolGroup/delete", param).then((res) => {
            this.handleRes(res, () => {
              this.$message.success("删除成功!");
              this.tableftInit();
            });
          });
        })
        .catch(() => {
          // 取消
        });
    },
    /**
     * @Description: 巡课内容-添加
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 17:01:30
     */
    addSchedule() {
      if (this.groupId == '') {
        return this.$message.warning('请先选择或添加巡课内容分组')
      }
      this.behaviorData.content = ''
      this.dialogObj1.title = "添加巡课内容";
      this.dialogObj1.dialogVisible = true;
      this.behaviorData.groupType = this.groupType;
      this.behaviorData.id = "";
      console.log('巡课内容表单', this.behaviorData)
    },
    /**
     * @Description: 巡课内容添加/编辑-取消
     * @DoWhat: 添加/编辑-取消
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 14:53:48
     */
    handleFormGroupClose1() {
      // this.$refs.ruleForm1.resetFields();
      this.behaviorData.type = '1'
      this.behaviorData.list = [
        {
          name: '',
          score: undefined,
          sort: '',
          lowScore: undefined,
          highScore: undefined
        },
        {
          name: '',
          score: undefined,
          sort: '',
          lowScore: undefined,
          highScore: undefined
        }
      ]
      this.behaviorData.list2 = [
        {
          name: '',
          score: undefined,
          sort: '',
          lowScore: undefined,
          highScore: undefined
        }
      ]
      this.dialogObj1.dialogVisible = false;
      console.log(this.behaviorData, 'behaviorData')
    },
    /**
     * @Description: 巡课内容添加/编辑-保存
     * @DoWhat: 添加/编辑-保存
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 14:52:40
     */
    changeGrouping() {
      this.$refs["ruleForm1"].validate((valid) => {
        if (valid) {
          const resName = this.behaviorData.list.some(item => item.name == '')
          const resScore = this.behaviorData.list.some(item => item.score == undefined)
          if (resName && this.behaviorData.type != '3') {
            this.$message.warning('有未填写的设置选项内容')
          } else if(resScore && this.behaviorData.type != '3' && this.setScore == '1') {
            this.$message.warning('有未填写的设置选项分值')
          } else if (this.behaviorData.type == '3' && this.setScore == '1' && (this.behaviorData.list2[0].lowScore == undefined || this.behaviorData.list2[0].highScore == undefined)) {
            this.$message.warning('请填写评价分值')
          } else if (this.behaviorData.type == '3' && this.setScore == '1' && (this.behaviorData.list2[0].lowScore >  this.behaviorData.list2[0].highScore)) {
            this.$message.warning('前边的分值应小于等于后边的分值')
          } else {
            const classSchedule = new classScheduleModel();
            //如果有行为标签
            if (this.tableftdata.length > 0) {
              if (this.groupId) {
                this.behaviorData.groupId = this.groupId;
              } else {
                this.behaviorData.groupId = this.tableftdata[0].id;
              }
              if (this.behaviorData.type == '1' || this.behaviorData.type == '2') {
                this.behaviorData.list.forEach((item, index) => {
                  item.sort = index + 1
                  delete item.lowScore
                  delete item.highScore
                  this.setScore == '0' && delete item.score
                })
              } else {
                this.behaviorData.list = this.setScore == '0' ? [] : [{name: '无', sort: 1, lowScore: this.behaviorData.list2[0].lowScore, highScore: this.behaviorData.list2[0].highScore}]
              }
              delete this.behaviorData.list2
              classSchedule
                .getBehaviorDataSave(this.behaviorData)
                .then((res) => {
                  this.handleRes(res, () => {
                    this.getList();
                    this.handleFormGroupClose1();
                  });
                });
            } else {
              this.$message.warning("请先设置巡课内容");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * @Description: 左侧栏添加按钮保存
     * @DoWhat: 添加-确定
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 10:16:22
     */
    handleFormGroupSave() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
            const classSchedule = new classScheduleModel();
            classSchedule
              .getBehaviorLabelSave(this.formGroupRuleForm)
              .then((res) => {
                this.handleRes(res, () => {
                  this.$message.success(`${this.dialogObj.title}成功`);
                  this.tableftInit();
                  this.handleFormGroupClose();
                });
              });

        } else {
          return false;
        }
      });
    },
    /**
     * @Description: 左侧栏添加
     * @DoWhat: 添加
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:18:21
     */
    createFormGroup() {
      this.dialogObj.title = "添加分组";
      this.groupingStatus = "add";
      this.formGroupRuleForm.id = ''
      this.formGroupRuleForm.groupName = '',
      this.formGroupRuleForm.groupType = '',
      this.formGroupRuleForm.setScore = '0',
      this.dialogObj.dialogVisible = true;
    },
    // 接口请求结果处理
    handleRes(res, fn) {
      if (res.data.code === "200") {
        fn();
      } else if (res.data && res.data.msg) {
        this.$message.error(res.data.msg);
      }
      /**
       * @Description: 巡课内容-编辑
       * @DoWhat: 编辑
       * @UseScenarios:
       * @Attention:
       * @Author: xwn
       * @Date: 2022-11-15 15:13:02
       */
    },
    edit(row) {
      this.behaviorData.id = row.id;
      this.behaviorData.content = row.content;
      this.behaviorData.type = row.type;
      this.behaviorData.groupId = row.groupId;
      this.behaviorData.groupType = row.groupType;
      this.behaviorData.list = []
      this.behaviorData.list2 = []
      if (row.list && row.type != '3') {
        row.list.forEach((item, index) => {
          this.behaviorData.list.push({
            name: item.name,
            score: item.score == null ? undefined : item.score,
            sort: item.sort,
            lowScore: item.lowScore == null ? undefined : item.lowScore,
            highScore: item.highScore == null ? undefined : item.highScore
          })
        })
        this.behaviorData.list2 = [{name: '无', sort: 1, lowScore: undefined, highScore: undefined}]
      } else if (row.list && row.type == '3') {
        this.behaviorData.list2 = row.list
      }
      if (this.setScore == '1' && this.behaviorData.type == '3' && this.behaviorData.list2.length == 0) {
         this.behaviorData.list2 = [{name: '无', sort: 1, lowScore: undefined, highScore: undefined}]
      }
      this.dialogObj1.title = "编辑巡课内容";
      this.dialogObj1.dialogVisible = true;
    },
    /**
     * @Description: 巡课内容-删除
     * @DoWhat: 删除
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 15:14:11
     */
    del(row) {
      this.$confirm("确认提示", {
        message: '该巡课内容将被删除，且不可恢复，是否继续',
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            id: row.id,
          };
          this._fet("/school/schoolPatrolContent/delete", param).then((res) => {
            this.handleRes(res, () => {
              this.$message.success("删除成功!");
              this.getList();
            });
          });
        })
        .catch(() => {
          // 取消
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.left-content {
  width: calc(100% - 290px);
  border-radius: 6px;
  overflow: hidden;
  float: left;
}

.filter-form {
  width: 100%;
  // height: 60px;
  display: flex;
  align-items: center;
  // line-height: 60px;
  padding: 10px 20px;
  background-color: #ffffff;
  margin-bottom: 10px;

  .title {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }

  .butn {
    margin-left: 27px;
  }
}

.sel-wrapper {
  display: flex;
  justify-content: center;
}

.btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.required-field {
  color: red;
  display: flex;
  align-items: center;
  width: 20px;
}
.content {
  min-height: 388px;
  .radioSet {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .drag-sort {
      width: 16px;
      height: 14px;
      cursor: move;
      margin-right: 5px;
    }
    .score {
      width: 185px;
      margin: 0 10px;
      .num {
        width: 94px;
      }
      .text {
        display: inline-block;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #808487;
        margin-left: 3px;
      }
    }
    .del {
      width: 54px;
      display: inline-block;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #F56C6C;
      cursor: pointer;
    }
    .del2 {
      width: 33px;
      margin-bottom: 5px;
      margin-left: 10px;
    }
  }
}
.add {
  display: inline-block;
  img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  span {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #3C7FFF;
  }
}
.setScore {
  display: flex;
  align-items: center;
  .line {
    display: inline-block;
    margin: 0 6px;
    width: 16px;
    height: 1px;
    background: #666666;
  }
}
.tip {
  margin-top: 5px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 16px;
    margin-right: 8px;
  }
}
.el-input .el-input__count .el-input__count-inner {
  height: 26px;
}
</style>
<style scoped>
  .show-cover-word ::v-deep .el-input__inner{
    padding-right: 47px;
  }
</style>
